@import '../../scss/_vars.scss';
@import '../../scss/_mixins.scss';

.image-title {
    width: 45vw;
    padding: 1em;
    margin: .5em;
    text-align: center;
    .image {
        width: 40%;
        height: auto;
    }
}
@media only screen and (min-width: 768px) {
    .image-title {
        width: 22vw;
    }
}
