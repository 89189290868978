$app-color: rgb(4, 110, 175);
$white: rgb(255, 255, 255);
$green: rgb(161, 237, 8);
$black: rgb(1, 14, 27);
$light-gray: rgb(248, 248, 255);
$gray: rgba(185, 193, 204,.8);
$duration: 0.3s;
$transition-timming: ease-in-out;

$desktop: 960px;
$tablet: 768px;
$mobile-large: 640px;
$mobile: 480px;
$mobile-small: 300px;