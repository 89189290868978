// @import '~scss/vars';
// @import '~scss/mixins';
@import '../../scss/_vars.scss';
@import '../../scss/_mixins.scss';

.header {
    position: sticky;
    top: 0;
    background-color: $white;
    height: 80px;
    align-items: center;
    box-shadow: 2px 2px 2px $light-gray;
    z-index: 10000;
    font-size: 10px;
    .app-navbar {
        display: flex;
        height: 100%;
    }
}

@media only screen and (min-width: 1024px) {
    .header {
        font-size: 14px;
        .app-mobile-navbar {
            display: none;
        }
        .app-navbar {
            display: flex;
            height: 100%;
        }
    }
}
@media only screen and (min-width: 1225px) {
    .header {
        font-size: 16px;
    }
}