@import '../../scss/_vars.scss';
@import '../../scss/_mixins.scss';

.image-content {
    background-color: $white;
    @include flex(flex,center,row);
    box-shadow: 2px 2px 2px $light-gray;
    // height: 55vh;
    height: auto;
    padding: 2em;
    div {
        flex-basis: 60%;
        @include flex(flex,center,column);
        padding: 0 4em;
        box-sizing: border-box;
        color: $black;
        h1 {
            font-size: 2.6em;
            margin: 0;
        }
        p {
            font-size: 1.4em;
            margin: .3em;
        }
    }
    img {
        // flex-basis: 25%;
        // height: 80%;
        // align-content: center;
        // align-self: center;
        flex-basis: 40%;
        width: 100%;
        height: auto;
        align-content: center;
        align-self: center;
        overflow: hidden;
    }
    &.left {
        background-color: $light-gray;
        img {
            order: 2;
        }
    }
    &.small-image {
        padding: 2em 2em;
        div {
            flex-basis: 90%;
        }
    }
}
.image-double {
    @include flex(flex,center,column);
    box-sizing: border-box;
    box-shadow: 2px 2px 2px 2px $gray;
    padding: 1em;
    height: 400px;
    width: 90vw;
    text-align: center;
    margin: 1em auto;
    background-color: $light-gray;
    div {
        @include flex(flex,center,column);
        padding: 1em 3em;
        box-sizing: border-box;
        color: $black;
        h1 {
            font-size: 1.6em;
            margin: 1em 0 0;
        }
        p {
            font-size: 1em;
            margin: .3em;
        }
    }
    img {
        align-content: center;
        align-self: center;
        margin-bottom: 1em;
        width: 90%;
        height: auto;
        max-height: 58%;
    }
}

@media only screen and (min-width: 768px) {
    .image-double {
        width: 42vw;
        // margin: 1em;
        flex-basis: 48%;
    }
}
