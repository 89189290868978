@import '../../scss/_vars.scss';
@import '../../scss/_mixins.scss';

.row-four {
    @include flex(flex,space-around,row);
    flex-wrap: wrap;
    padding: 2em;
    background-color: $light-gray;
}

.addval {
    font-size: 12px;
}
