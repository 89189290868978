
@import '../../scss/_vars.scss';
@import '../../scss/_mixins.scss';
.residential {
    background-image: url('../../assetfiles/images/house-3.jpg');
    background-color: white;
    padding-top: 20em;
    background-size: 100% 20%;
    background-repeat: no-repeat;
    &__fullwidth {
        position: absolute;
        bottom: 42%;
        left: 39.5%;
        width: 26%;
        margin: 0 auto;
        text-align: center;
    }
}